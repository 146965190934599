<template>
  <div
    class="flex-1 flex max-w-[1680px] w-full mx-auto h-full gb-[#f5f5f5]"
    v-loading="loading"
  >
    <div class="w-full h-full overflow-hidden">
      <div class="h-full overflow-hidden relative flex flex-col">
        <steps :current="current"></steps>
        <div class="flex-1 overflow-auto mx-6 mt-5">
          <div class="overflow-auto">
            <!-- 步骤1 -->
            <div v-show="current == 1">
              <promotion
                :promotionObjectType.sync="data.promotionObjectType"
                :objective.sync="data.objective"
                :enumConstants="enumConstants"
              ></promotion>
              <campaignInfo
                v-model="data"
                ref="campaignInfo"
                @getAllAdAccountId="getAllAdAccountId"
                @changePromotionLink="changePromotionLink"
              ></campaignInfo>
            </div>
            <!-- 步骤2 -->
            <div v-show="current == 2">
              <audience
                v-model="data.adGroupTargeting"
                :data="data"
                :enumConstants="enumConstants"
                ref="audience"
              ></audience>
            </div>
            <!-- 步骤3 -->
            <div v-show="current == 3">
              <creatives
                v-model="data.creatives"
                :data="data"
                ref="creatives"
                :enumConstants="enumConstants"
                @addCreative="addCreative"
              ></creatives>
            </div>
            <div v-if="current == 4">
              <track-creat
                v-model="data.adGroupOption"
                :enumConstants="enumConstants"
                :params="data"
                ref="trackCreat"
                :allAdAccountId="allAdAccountId"
              ></track-creat>
            </div>
          </div>
        </div>
      </div>
      <div class="flex justify-between bg-white p-3 mt-6 mx-6">
        <el-button @click="exit">退出</el-button>
        <div>
          <el-button
            type="primary"
            @click="preStep"
            v-if="current > 1"
            >上一步</el-button
          >
          <el-button
            type="primary"
            @click="nextStep"
            >{{ current == 4 ? '确认' : '下一步' }}</el-button
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import steps from './components/steps/index.vue';
import promotion from './components/promotion/index.vue';
import campaignInfo from './components/campaignInfo/index.vue';
import audience from './components/audience/index.vue';
import trackCreat from './components/trackCreat/index.vue';
import { getAudienceDefault } from './index.js';
import creatives from './components/creatives/index.vue';
import { getEnumConstants } from '@/api/snapchat/create.js';
export default {
  components: { steps, promotion, campaignInfo, audience, creatives, trackCreat },
  data() {
    return {
      loading: false,
      allAdAccountId: [], // 账户列表
      enumConstants: {
        promotionObjectTypes: [],
        objectives: [],
        optimizationGoals: [],
        bidStrategies: [],
        budgetTypes: [],
        conversionWindows: [],
        statuses: [],
        genders: [],
        callToActions: [],
      },
      current: 1,
      data: {
        promotionObjectType: 'LANDING_PAGE', // 推广对象
        objective: 'WEB_CONVERSION', // 推广目标
        adAccountId: null, // 广告账户
        promotionInfo: {
          // 推广数据
          promotionLink: null,
          sale: null,
          site: null,
        },
        pixelId: null, // 像素id
        adGroupTargeting: [getAudienceDefault()], // 受众
        creatives: [
          {
            type: 'WEB_VIEW',
            callToAction: 'MORE',
            infos: [],
            additionalCreativeInfo: {},
            selectImgIndex: null,
          },
        ], // 创意
      },
    };
  },
  methods: {
    addCreative() {
      let newCreative = {
        type: 'WEB_VIEW',
        callToAction: 'MORE',
        infos: [],
        selectImgIndex: null,
        brandName: this.data.promotionInfo.site.domain,
        webViewUrl: this.data.promotionInfo.promotionLink,
        additionalCreativeInfo: {},
      };
      this.data.creatives.push(newCreative);
    },
    preStep() {
      this.current -= 1;
    },
    nextStep() {
      switch (this.current) {
        case 1:
          this.$refs.campaignInfo.validate().then(() => {
            if (this.data.promotionInfo.sale.status == 4) {
              this.$confirm('该商品状态目前未上架，可能会产生无效花费；确定继续投放吗', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
              })
                .then((res) => {
                  const creatives = this.data.creatives.map((item) => {
                    return {
                      ...item,
                      brandName: this.data.promotionInfo.site.domain,
                      webViewUrl: this.data.promotionInfo.promotionLink,
                    };
                  });
                  this.$set(this.data, 'creatives', creatives);
                  this.current += 1;
                  this.$refs.audience.clearValidate();
                })
                .catch((res) => {});
            } else {
              const creatives = this.data.creatives.map((item) => {
                return {
                  ...item,
                  brandName: this.data.promotionInfo.site.domain,
                  webViewUrl: this.data.promotionInfo.promotionLink,
                };
              });
              this.$set(this.data, 'creatives', creatives);
              this.current += 1;
              this.$refs.audience.clearValidate();
            }
          });
          break;
        case 2:
          this.$refs.audience.validate().then(() => {
            this.current += 1;
            this.$refs.creatives.clearValidate();
          });
          break;

        case 3:
          this.$refs.creatives.validate().then((res) => {
            this.current += 1;
            // 进入第四步拆分表格
            this.$nextTick(() => {
              this.$refs.trackCreat.splitView();
            });
          });
          break;
        case 4:
          // 点击确认
          this.$refs.trackCreat.creatAdsClick();
          break;
      }
    },
    getEnumConstants() {
      this.loading = true;
      getEnumConstants()
        .then((res) => {
          if (res.code == 0) {
            this.enumConstants = res.data;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getAllAdAccountId(list) {
      this.allAdAccountId = list;
    },
    exit() {
      this.$router.replace('/multiPlatform/index');
    },
    // 修改推广连接
    changePromotionLink() {
      let adGroupTargeting = [];
      this.data.adGroupTargeting.forEach((targeting, index) => {
        adGroupTargeting.push({
          ...targeting,
          audienceSize: {
            success: true,
          },
          geos: null,
          interests: {
            custom: [],
          },
        });
      });
      this.data.adGroupTargeting = [...adGroupTargeting];
    },
  },
  created() {
    this.getEnumConstants();
  },
  beforeRouteLeave(to, form, next) {
    this.$confirm('确定退出，输入的内容将清空', {
      confirmButtonText: '退出',
      cancelButtonText: '取消',
      type: 'warning',
    })
      .then((res) => {
        this.$store.dispatch('app/openSideBar');
        next();
      })
      .catch(() => {
        next(false);
      });
  },
};
</script>
